import styled from "styled-components";
import { motion } from "framer-motion";
import Icons from "../assets/icons";
import { useEffect, useRef, useState } from "react";
import API from "../api/api";
import Paging from "./Pagenation";
import { Link, useLocation } from "react-router-dom";
import scrollToRef from "../utils/scrollTarget";

interface DataType {
  id: number;
  title: string;
  term: string;
  created_at: string;
  isActive: boolean;
  status_id: number;
  type_id: number;
}

const contentsData = [
  {
    title: ["benefits_con1"],
    value: ["benefits_con1_value", "benefits_con1_value_2"],
    icon: <Icons.IconBonus2 />,
  },
  {
    title: ["benefits_con2"],
    value: ["benefits_con2_value", ""],
    icon: <Icons.IconOutstanding2 />,
  },
  // {
  //   title: ["benefits_con3"],
  //   value: ["benefits_con3_value","benefits_con3_value_2"],
  //   icon: <Icons.IconEducation2 />,
  // },
  // {
  //   title: ["benefits_con4"],
  //   value: ["benefits_con4_value","benefits_con4_value_2"],
  //   icon: <Icons.IconCall2 />,
  // },
  {
    title: ["benefits_con6", "benefits_con6_2"],
    value: ["benefits_con6_value", "benefits_con6_value_2"],
    icon: <Icons.IconHealthCheck2 />,
  },
  {
    title: ["benefits_con7"],
    value: ["benefits_con7_value", "benefits_con7_value_2"],
    icon: <Icons.IconResort2 />,
  },
  {
    title: ["benefits_con8"],
    value: ["benefits_con8_value", ""],
    icon: <Icons.IconGift2 />,
  },
  {
    title: ["benefits_con9"],
    value: ["benefits_con9_value", "benefits_con9_value_2"],
    icon: <Icons.IconCongratulatoryEvents2 />,
  },
  {
    title: ["benefits_con5"],
    value: ["benefits_con5_value", "benefits_con5_value_2"],
    icon: <Icons.IconAccident2 />,
  },
  {
    title: ["benefits_con10", "benefits_con10_2"],
    value: ["benefits_con10_value", "benefits_con10_value_2"],
    icon: <Icons.IconTravelInsurance2 />,
  },
];
const RecruitStyle = styled.ul`
  .scroll {
    min-height: calc(var(--vh, 1vh) * 100 - 50px);
    position: relative;
  }
  h1.main-title {
    font-size: 30px;
    color: #2d59a2;
    display: flex;
    border-bottom: 1px solid #2d59a2;
    margin-bottom: 50px;
    ::before {
      /* 구분자 숨김 240809 */
      /* content: ""; */
      width: 6px;
      height: 22px;
      margin: auto 7px auto 0;
      background-color: #2d59a2;
    }
  }
  .middle-title {
    font-size: 30px;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 60px;
    color: #2d59a2;
  }
  .article-title {
    padding: 10px 30px;
    margin-top: 30px;
    background-color: #2d59a2;
    color: #fff;
    width: fit-content;
  }
  .button-area {
    margin: 50px auto;
    display: flex;
    width: 95%;
    max-width: 1200px;
    border-bottom: 2px solid #2d59a2;
    .button {
      font-size: 24px;
      min-width: 200px;
      width: 100%;
      text-align: center;
      height: 60px;
      display: flex;
      font-weight: bold;
      transition: 0.3s;
      p {
        margin: auto;
      }
    }
    .button.active {
      color: #fff;
      background: #2d59a2;
    }
  }
  @media (max-width: 768px) {
    .middle-title,
    h1.main-title {
      font-size: 20px;
      margin-bottom: unset;
      margin: 40px 5px;
      word-break: keep-all;
    }
    .button-area {
      .button {
        font-size: 16px;
        min-width: unset;
        height: 36px;
      }
      .button.active {
        color: #fff;
        background: #2d59a2;
      }
    }
  }
`;

const RecruitMainStyle = styled.div`
  .scroll {
    min-height: 600px;
  }
  .banner-image {
    background: url("/background/welfare-3.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    .banner-text {
      margin: auto;
      background: #ffffff99;
      width: 100%;
      padding: 50px 0;
      text-align: center;
      h1 {
        font-size: 60px;
        margin: auto;
        line-height: 1em;
      }
    }
  }
  @media (max-width: 768px) {
    .scroll {
      min-height: 400px;
    }
    .banner-image {
      .banner-text {
        h1 {
          font-size: 30px;
        }
      }
    }
  }
`;
const RecruitMain = ({ t }: any) => {
  return (
    <RecruitMainStyle>
      <div className="banner-image scroll">
        <motion.div
          initial={{ transform: "scaleX(0)", opacity: 0 }}
          animate={{ transform: "scaleX(1)", opacity: 1 }}
          transition={{ ease: "easeOut", duration: 0.7 }}
          className="banner-text"
        >
          <motion.h1
            initial={{ translateY: "-50px", opacity: 0 }}
            animate={{ translateY: "0", opacity: 1 }}
            transition={{ delay: 0.3, ease: "easeInOut", duration: 1 }}
          >
            {t("recruit")}
          </motion.h1>
        </motion.div>
      </div>
    </RecruitMainStyle>
  );
};
const RecruitInfoStyle = styled.div`
  .scroll {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: unset !important;
    min-height: unset !important;
    max-width: 1200px;
    width: 95%;
    margin: 0 auto;
    ul > h1 {
      text-align: center;
      padding: 30px 0;
      background: #fff;
      font-size: 30px;
    }
  }
  .recruit-box {
    width: 100%;
    padding: 0px 0;
    h2 {
      font-size: 20px;
      display: flex;
      border-radius: 3px;
    }
    .recruit-info-top {
      h1 {
        font-size: 30px;
        color: #2d59a2;
        display: flex;
        border-bottom: 1px solid #2d59a2;
        margin-bottom: 10px;
        ::before {
          /* 구분자 숨김 240809 */
          /* content: ""; */
          width: 6px;
          height: 22px;
          margin: auto 7px auto 0;
          background-color: #2d59a2;
        }
      }
      .process {
        width: 100%;
        margin: auto;
        li + div {
          margin: auto 10px;
        }
        li {
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          height: 300px;
        }
        &.ko li {
          background-image: url("/background/recruit-03.svg");
        }
        &.en li {
          background-image: url("/background/recruit-03-en.svg");
        }
        .recruit-arrow-r {
          display: flex;
          svg {
            path {
              stroke: #026;
            }
          }
        }
      }
    }
    .recruit-info-bottom {
      display: flex;
      & > div + div {
        margin-left: 20px;
      }
      .recruit-info-bottom-left {
        width: 100%;
        div:first-child {
          min-width: 100px;
        }
      }
      .recruit-info-bottom-right {
        width: 100%;
        .partners-bottom {
          margin: 30px 0 70px;
          .partners-bottom-box {
            display: flex;
            &:nth-of-type(1) {
              border-bottom: 2px solid #fff;
            }
            > h2 {
              min-width: 250px;
              background: #4699f9;
              color: #fff;
              min-height: 50px;
              display: flex;
              border-radius: unset;
              justify-content: space-between;
              position: relative;
              span {
                margin: auto 0;
                text-indent: 1em;
              }
              b {
                width: 30px;
                aspect-ratio: 1;
                margin: auto 20px;
                font-size: 0;
                display: flex;
                svg {
                  width: 100%;
                  height: 100%;
                }
              }
            }
            > p {
              text-align: left;
              padding: 0 20px;
              display: flex;
              min-width: 220px;
              span {
                width: 100%;
                margin: auto 0;
              }
            }
            .button2 {
              width: fit-content;
              background-color: none;
              border: 1px solid #2d59a2;
              display: flex;
              height: fit-content;
              margin: auto 0;
              padding: 5px 20px;
              position: relative;
              transition: 0.3s;
              border-radius: 5px;
              ::after {
                content: "";
                position: absolute;
                z-index: -1;
                background-color: #2d59a2;
                width: 0%;
                height: 100%;
                left: 0%;
                top: 0%;
                transition: 0.3s;
              }
              :hover {
                color: #fff;
                ::after {
                  content: "";
                  position: absolute;
                  z-index: -1;
                  background-color: #2d59a2;
                  width: 100%;
                  height: 100%;
                  left: 0%;
                  top: 0%;
                }
                svg {
                  path {
                    fill: #fff;
                  }
                }
              }
              p {
                padding: 0;
                margin: auto;
                display: flex;
                svg {
                  path {
                    fill: #000;
                    transition: 0.3s;
                  }
                }
              }
            }
          }
        }
      }
      ul {
        li {
          display: flex;
          div {
            padding: 5px 10px;
            &:first-child {
              font-weight: bold;
            }
          }
        }
      }
    }
    .inner-box > div + div {
      margin-top: 50px;
    }
  }
  .inner-box {
    margin: auto;
  }
  .button {
    display: flex;
    text-align: center;
    color: #fff;
    transition: 0.3s;
    box-shadow: 2px 2px 5px 0 #00000022;
    border-radius: 3px;
    background: #05f;
    justify-content: center;
    font-size: 16px;
    margin-left: 20px;
    position: relative;
    p {
      margin: auto 0;
      display: flex;
    }
    &:hover {
      transition: 0.1s;
      background: #16f;
    }
    &:active {
      transition: 0.1s;
      background: #04c;
      box-shadow: 0px 0px 2px 0 #00000022;
      transform: translate(1px, 1px);
    }
  }
  a {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0%;
  }
  @media (max-width: 768px) {
    text-align: center;
    .recruit-box {
      h2 {
        padding: 2px 5px;
        text-align: left;
        font-size: 18px;
      }
      .recruit-info-top {
        h1 {
          font-size: 20px;
        }
        .process {
          li {
            height: 150px;
          }
        }
      }
      .recruit-info-bottom {
        display: unset;
        & > div + div {
          margin-left: unset;
          margin-top: 30px;
        }
        .recruit-info-bottom-right {
          .partners-bottom {
            margin: 10px 0 50px;
            .partners-bottom-box {
              > h2 {
                min-height: 36px;
                min-width: 150px;
                span {
                  text-indent: unset;
                  font-size: 16px;
                }
                b {
                  width: 20px;
                  margin: auto 0px;
                }
              }
              > p {
                min-height: 36px;
                font-size: 14px;
                padding: 0 10px;
              }
              .button2 {
                width: fit-content;
                background-color: none;
                border: 1px solid #2d59a2;
                display: flex;
                height: fit-content;
                margin: auto 0;
                padding: 5px 20px;
                position: relative;
                transition: 0.3s;
                border-radius: 5px;
                ::after {
                  content: "";
                  position: absolute;
                  z-index: -1;
                  background-color: #2d59a2;
                  width: 0%;
                  height: 100%;
                  left: 0%;
                  top: 0%;
                  transition: 0.3s;
                }
                :hover {
                  color: #fff;
                  ::after {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    background-color: #2d59a2;
                    width: 100%;
                    height: 100%;
                    left: 0%;
                    top: 0%;
                  }
                  svg {
                    path {
                      fill: #fff;
                    }
                  }
                }
                p {
                  padding: 0;
                  margin: auto;
                  display: flex;
                  svg {
                    path {
                      fill: #000;
                      transition: 0.3s;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .inner-box > div + div {
        margin-top: unset;
      }
    }
  }
`;
const RecruitInfo = ({ t, i18n }: any) => {
  const up1 = useRef(null);
  const options = {
    root: null,
    rootMargin: "-10%",
    threshold: 0,
  };
  useEffect(() => {
    const observer = new IntersectionObserver((e) => {
      e.map((f) => {
        return f.isIntersecting && f.target.classList.add("up");
      });
    }, options);
    [up1].forEach((ref) => ref.current && observer.observe(ref.current));
  });
  return (
    <RecruitInfoStyle>
      <div className="scroll">
        <ul>
          <li className="recruit-box">
            <div className="inner-box up-soon" ref={up1}>
              <div className="recruit-info-top">
                <h1>
                  <p>{t("recruitProgress")}</p>
                </h1>
                <ul
                  className={`process ${i18n.language === "ko" ? "ko" : "en"}`}
                >
                  <li></li>
                </ul>
              </div>
              <div className="recruit-info-bottom">
                <div className="recruit-info-bottom-right">
                  <h2 className="article-title">
                    <p>{t("recruitApply")}</p>
                  </h2>
                  <div className="partners-bottom">
                    <div className="partners-bottom-box">
                      <h2>
                        <span>{t("recruitApply_1")}</span>
                        <b>
                          <Icons.IconDownload />
                        </b>
                        <a
                          href={
                            "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/scgs.docx"
                          }
                          download
                          target="_blank"
                        />
                      </h2>
                      <p>
                        <span>recruit@scgs.co.kr</span>
                      </p>
                    </div>
                    <div className="partners-bottom-box">
                      <h2>
                        <span>{t("recruitApply_2")}</span>
                      </h2>
                      <p>
                        <span>{t("recruitApply_3")}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <h1 className="main-title">
                <p>{t("benefits")}</p>
              </h1>
            </div>
          </li>
        </ul>
      </div>
    </RecruitInfoStyle>
  );
};

const RecruitNoticeStyle = styled.div`
  .scroll {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #ffffff99;
    max-width: 1200px;
    margin: 0 auto;
    min-height: unset;
    width: 95%;
    & > ul {
      margin: 20px auto;
    }
  }
  h1 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .recruit-notice {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    color: #666;
    ul {
      display: flex;
      flex-direction: column;
      width: inherit;
      margin: 0 auto;
      .recruit-notice-head {
        display: flex;
        width: inherit;
        background: #2d59a2;
        color: #fff;
        font-weight: bold;
        p {
          font-size: 20px;
        }
        .title {
          text-align: center;
        }
      }
      li {
        display: flex;
        cursor: pointer;
        position: relative;
        border-bottom: solid #ccc 1px;
        &:hover {
          transition: 0.1s;
          background: #eee;
        }
        a {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      p {
        padding: 14px 0;
        line-height: 1em;
        text-align: center;
        font-size: 18px;
        &.number {
          min-width: 150px;
        }
        &.title {
          text-align: left;
          min-width: 200px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &.term {
          min-width: 250px;
        }
        &.date {
          min-width: 150px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .scroll {
      & > ul {
        margin: 10px auto;
      }
      h1 {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }
    .recruit-notice {
      width: 100%;
      ul {
        .recruit-notice-head {
          height: 36px;
          align-items: center;
          p {
            font-size: 14px;
          }
        }
        li {
          display: flex;
          cursor: pointer;
          position: relative;
          height: 36px;
          align-items: center;
          &:hover {
            transition: 0.1s;
            background: #eee;
          }
          a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
        p {
          padding: unset;
          font-size: 13px;
          &.number {
            min-width: 60px;
          }
          &.title {
            text-align: left;
            min-width: 100px;
            width: 100%;
          }
          &.term {
            min-width: 80px;
          }
          &.date {
            min-width: 70px;
          }
        }
      }
    }
  }
`;
const RecruitNotice = ({ t }: any) => {
  const [dataList, setDataList] = useState([]);
  const [pageNum, setPageNum] = useState(Number);
  const [lastPageNum, setLastPageNum] = useState();
  useEffect(() => {
    API.BOARD_LIST({
      setDataList: setDataList,
      boardName: "Board_Employment",
      setPageNum: setPageNum,
      setLastPageNum: setLastPageNum,
      pageNum: pageNum,
    });
  }, [pageNum, lastPageNum]);

  return (
    <RecruitNoticeStyle>
      <div className="scroll">
        <div className="recruit-notice">
          <ul>
            <div className="recruit-notice-head">
              <p className="number">{t("recruitClass")}</p>
              <p className="title">{t("newsTitle")}</p>
              <p className="term">{t("recruitTerm")}</p>
              <p className="date">{t("newsDate")}</p>
            </div>
            {dataList.map((res: DataType, idx) => {
              return (
                <li key={idx}>
                  <p className="number">
                    {res.type_id === 1 && t("recruitNewcomer")}
                    {res.type_id === 2 && t("recruitCareer")}
                    {res.type_id === 3 && t("recruitNewCareer")}
                    {res.type_id === 4 && t("recruitIntern")}
                  </p>
                  <p className="title">{res.title}</p>
                  <p className="term">{res.term}</p>
                  <p className="date">{`${res.created_at
                    .slice(0, 10)
                    .replace(/-/g, ".")}`}</p>
                  <Link to={`${res.id}`}></Link>
                </li>
              );
            })}
          </ul>
        </div>
        {Paging({
          lastPageNum: lastPageNum,
          pageNum: pageNum,
          setPageNum: setPageNum,
        })}
      </div>
    </RecruitNoticeStyle>
  );
};
const RecruitWelfareStyle = styled.div`
  text-align: center;
  background: url("/background/recruit-3.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  .background {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff66;
    top: 0;
    left: 0;
  }
  .scroll {
    position: relative;
    z-index: 1;
    min-height: unset;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: auto;
    width: 96%;
    margin-bottom: 50px;
  }
  .recruit-welfare-head {
    margin-bottom: 50px;
    h1 {
      font-size: 30px;
    }
    p {
      font-size: 30px;
      margin-top: 20px;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    max-width: 1000px;
    margin: auto;
    .welfare {
      width: calc(100% / 4 - 6px);
      min-width: 150px;
      aspect-ratio: 4/5;
      display: flex;
      flex-direction: column;
      background: #004c59b3;
      color: #fff;
      padding: 20px 0px;
      margin: 3px;
      justify-content: center;
      transition: 0.1s;
      max-height: 300px;
      :hover {
        width: calc(100% / 4);
        padding: 0px;
        margin: 0px;
        svg {
          transform: scale(1.1);
        }
      }
      span {
        height: 60px;
        width: fit-content;
        display: flex;
        aspect-ratio: 1;
        margin: 0 auto;
        svg {
          path {
            fill: #fff;
          }
          width: auto;
          height: auto;
          transition: 0.3s;
        }
      }
      h2 {
        font-size: 30px;
        margin: 20px 0;
        letter-spacing: -1px;
        line-height: 1.1em;
      }
      p {
        font-size: 16px;
        min-height: 24px;
        line-height: 1.1em;
      }
    }
  }

  @media (max-width: 768px) {
    .scroll {
      padding: 30px 5px;
    }

    ul {
      margin: auto;
      .welfare {
        line-height: unset;
        span {
          height: 36px;
        }
        h2 {
          font-size: 25px;
          margin-top: 10px;
        }
        p {
          height: 24px;
        }
      }
      .recruit-box {
        width: calc(50% - 4px);
        min-width: unset;
        aspect-ratio: unset;
        padding: 20px 0px;
        margin: 2px;
        justify-content: center;
        transition: 0.3s;
        pointer-events: none;
        svg {
          transition: 0.3s;
        }
      }
    }
  }
`;
const RecruitWelfare = ({ t }: any) => {
  const up1 = useRef(null);
  const options = {
    root: null,
    rootMargin: "-10%",
    threshold: 0,
  };
  useEffect(() => {
    const observer = new IntersectionObserver((e) => {
      e.map((f) => {
        return f.isIntersecting && f.target.classList.add("up");
      });
    }, options);
    [up1].forEach((ref) => ref.current && observer.observe(ref.current));
  });
  return (
    <RecruitWelfareStyle>
      <div className="scroll">
        <ul className="up-soon" ref={up1}>
          {contentsData.map((res, idx) => {
            return (
              <li key={idx} className="recruit-box welfare">
                <span>{res.icon}</span>
                <h2>
                  {t(res.title[0])}
                  {res.title.length > 1 && (
                    <>
                      <br></br>
                      {t(res.title[1])}
                    </>
                  )}
                </h2>
                <p>{t(res.value[0])}</p>
                <p>{res.value.length > 1 && t(res.value[1])}</p>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="background"></div>
    </RecruitWelfareStyle>
  );
};
const Recruit = ({ t, i18n }: any) => {
  const location = useLocation();
  const [recruitState, setRecruitState] = useState(location.state?.type || 0);
  const ref1 = useRef(null);
  useEffect(() => {
    scrollToRef(ref1, location);
  }, [location.state]);
  useEffect(() => {
    i18n.language !== "ko" && setRecruitState(0);
  }, [i18n.language]);

  return (
    <RecruitStyle className="no-select">
      <RecruitMain t={t} />
      <h1 ref={ref1} className="middle-title">
        {t("recruitTitle")}
      </h1>
      {i18n.language === "ko" && (
        <ul className="button-area pointer">
          <li
            onClick={() => {
              recruitState === 1 && setRecruitState(0);
            }}
            className={`${recruitState === 0 ? "active" : ""} button`}
          >
            <p>{t("recruitInfo")}</p>
          </li>
          <li
            onClick={() => {
              recruitState === 0 && setRecruitState(1);
            }}
            className={`${recruitState === 1 ? "active" : ""} button`}
          >
            <p>{t("recruitPosts")}</p>
          </li>
        </ul>
      )}
      {recruitState === 0 ? (
        <>
          <RecruitInfo t={t} i18n={i18n} />
          <RecruitWelfare t={t} />
        </>
      ) : (
        <RecruitNotice t={t} />
      )}
    </RecruitStyle>
  );
};

export default Recruit;
